import { useState, useEffect } from "react";

const siteConfigUrl = "config.json";

export type SiteConfig = Map<string, string>;

export const getSiteConfig = <SiteConfig,>() => {
  const [state, setState] = useState<SiteConfig>();

  useEffect(() => {
    const dataFetch = async () => {
      const data = await await fetch(siteConfigUrl)
        .then((response) => {
          if (response.ok) {
            return response.json();
          }
          throw new Error("Unable to load site config");
        })
        .then((configJson) => {
          return configJson;
        })
        .catch((error) => {
          console.log(error);
          return {};
        });

      setState(data);
    };

    dataFetch();
  }, []);

  return { config: state };
};
