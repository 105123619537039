import { SiteConfig, getSiteConfig } from "./utils/SiteConfig";

const Config = () => {
  const { config } = getSiteConfig<SiteConfig>();

  if (!config) return <div className="loading site-config-loading" />;

  return (
    <div className="site-config">
      <h2>Site Config</h2>
      <ul>
        {Object.keys(config).map((key) => {
          return (
            <li key={key}>
              {key}: {config[key]}
            </li>
          );
        })}
      </ul>
    </div>
  );
};

const App = () => {
  const header = <h1>Example Frontend Service</h1>;

  return (
    <div>
      {header}
      <Config />
    </div>
  );
};

export default App;
